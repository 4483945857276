*{
  font-family: 'Comfortaa', cursive !important;
}


a{
  color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.download-table-xls-button{
  width: 200px;
  font-size: 0.9rem;
  background: #31BE7D;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #FFF;
  border-radius: 5px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.download-table-xls-button img{
  width: 30px;
}
.download-table-xls-button:hover{
  box-shadow: 0px 0px 8px #31BE7D;
}

.alert-info{
    margin: 0 30px 0px 30px;
    text-align: center;
    padding: 25px;
    border-radius: 30px;
    font-weight: bold;
    color: #055160 !important;
    background-color: #cff4fc;
}